import axios from "axios";
import { TokenAuthService } from "../service/TokenAuthService";

const apiUrl = process.env.REACT_APP_API_URL;

axios.interceptors.request.use(
    (config) => {
        //   const { origin } = new URL(config.url);
        //  const allowedOrigins = [apiUrl];

        let tokenAuthService = new TokenAuthService();
        let token = tokenAuthService.getToken();
        //  if (allowedOrigins.includes(origin)) {
        config.headers.authorization = `Bearer ${token}`;
        //    }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export class ProductService {
    getProductsSmall() {
        return axios.get("assets/demo/data/products-small.json").then((res) => res.data.data);
    }

    getProductsWithOrdersSmall() {
        return axios.get("assets/demo/data/products-orders-small.json").then((res) => res.data.data);
    }

    getProducts() {
        return axios.get(`${apiUrl}/products/`).then((res) => res.data.result);
    }

    updateProduct(product) {
        return axios.put(`${apiUrl}/products/`, product).then((res) => res.data.result);
    }

    createProduct(product) {
        return axios.post(`${apiUrl}/products/`, product).then((res) => {
            return res.data.result;
        });
    }

    deleteProduct(id) {
        return axios.post(`${apiUrl}/products/` + id, { _method: "DELETE" }).then((res) => {
            return res.data;
        });
    }

    getProduct(id) {
        return axios.get(`${apiUrl}/products/`, id).then((res) => res.data.result);
    }
}
