import axios from "../utilities/api-axios"

const apiUrl = process.env.REACT_APP_API_URL;


export class UserService {
    async getUsers() {
        return await axios.get(`${apiUrl}/users/`).then((res) => res.data);
    }

    async get(id) {
        return await axios.get(`${apiUrl}/users/` + id).then((res) => res.data);
    }

    async getUsersByClub(clubId) {
        return await axios.get(`${apiUrl}/users/byClub/` + clubId).then((res) => res.data);
    }

    async getMyClub() {
        return await axios.get(`${apiUrl}/users/myClub/`).then((res) => res.data);
    }

    async getUsersByMail(mail) {
        return await axios.get(`${apiUrl}/users/byMail/` + mail).then((res) => res.data);
    }

    async sendPassCodeToEmail(mail) {
        let request = { "Email": mail};
        const res = await axios.put(`${apiUrl}/auth/sendPassCodeToEmail/` , request);
        return res.data;
    }

    async validatePassCode(mail, code) {
        let request = { "Email": mail, "PassCode": code};
        const res = await axios.put(`${apiUrl}/auth/validatePassCode/` , request);
        return res.data;
    }

    async updatePasswordByEmail(mail, newPassword) {
        const request = {
            Email: mail,
            Password: newPassword,
        };
        return await axios.put(`${apiUrl}/users/updatePasswordWithEmail/`, request).then((res) => res.data);
    }

    async updatePassword(userId, newPassword) {
        const request = {
            UserId: userId,
            Password: newPassword,
        };
        return await axios.patch(`${apiUrl}/users/updatePassword/`, request).then((res) => res.data);
    }

    async updateEmailValidatedByPassword(email, password) {
        const request = {
            Email: email,
            Password: password,
        };
        return await axios.put(`${apiUrl}/users/updateEmailValidatedByPassword/`, request).then((res) => res.data);
    }

    async updatePasswordWithValidation(oldPassword, password) {
        const request = {
            OldPassword: oldPassword,
            Password: password,
        };
        return await axios.put(`${apiUrl}/users/updatePasswordWithValidation/`, request).then((res) => res.data);
    }

    async updateName(user) {
        const request = {
            UserId: user.useId,
            FirstName: user.useName,
            LastName: user.useSurname,
        };
        return await axios.put(`${apiUrl}/users/updateName/`, request).then((res) => res.data);
    }

    async updateUser(user) {
        const updUser = {
            Email: user.useEmail,
            Password: user.usePassword,
            FirstName: user.useName,
            LastName: user.useSurname,
            Address: user.useDirection,
            Phone: user.usePhone,
            StatusId: user.stsId,
            Roles: [user.isPlanner, user.isViewer, user.isDirector],
            ClubId: user.clubId,
        };

        const res = await axios.put(`${apiUrl}/users/roles/` + user.useId, updUser);
        return res.data;
    }

    async createUser(user) {
        let res = "";

        const createUser = {
            Email: user.useEmail,
            Password: "",
            FirstName: user.useName,
            LastName: user.useSurname,
            Address: user.useDirection,
            Phone: user.usePhone,
            StatusId: user.stsId,
            Roles: [user.isPlanner, user.isViewer, user.isDirector],
            ClubId: user.clubId,
        };
        res = await axios.post(`${apiUrl}/users/roles/`, createUser);

        return res.data;
    }

    async deleteRoleUser(userId) {
        const res = await axios.delete(`${apiUrl}/users/roluser/` + userId, { _method: "DELETE" });
        return res.data;
    }

    async uploadAvatar(file, userId) {
        let formData = new FormData();
        formData.append('file', file);
        return await axios.post(`${apiUrl}/commonServices/uploadAvatar/` + userId, formData);
    }
}
