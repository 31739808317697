import axios from 'axios';
import { TokenAuthService } from "../service/TokenAuthService";
import Cookies from "universal-cookie";
import jwt_decode from "jwt-decode";

const apiUrl = process.env.REACT_APP_API_URL;


axios.interceptors.request.use(
    (config) => {
        let tokenAuthService = new TokenAuthService();
        let token = tokenAuthService.getToken();

        config.headers.authorization = `Bearer ${token}`;
        config.headers.backofficelanguage = navigator.language || navigator.userLanguage;

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);


axios.interceptors.response.use(  (response) => {
    // Return a successful response back to the calling service
    return response;
  }, async(error) => {
    // Return any error which is not due to authentication back to the calling service
    const originalRequest = error.config;
    if (!error.response) {
        return new Promise((resolve, reject) => {
            new TokenAuthService().logout();
            redirectToLogin(error);
            reject(error);
        });
    }
    if (error.response.status !== 401) {
      return new Promise((resolve, reject) => {
        reject(error);
      });
    }
    else{
        if(error.response && error.response.data && error.response.data.message === 'jwt expired'){
            let tokenAuthService = new TokenAuthService();
            try {
                const res = await tokenAuthService.refreshToken();

                if(res && res.data && res.data.name === 'TokenExpiredError'){
                    return new Promise((resolve, reject) => {
                        new TokenAuthService().logout();
                        redirectToLogin(error);
                        reject('Refresh Token Expired');
                    });
                }

                const cookies = new Cookies();
                cookies.set("gonni.token", res.data.accessToken, { path: "/" });
                cookies.set("gonni.refreshtoken", res.data.refreshToken, { path: "/" });
                axios.defaults.headers.authorization = `Bearer ${res.data.accessToken}`;
                return axios(originalRequest);
            } catch (error) {
                return new Promise((resolve, reject) => {

                    new TokenAuthService().logout();
                    redirectToLogin(error);
                    reject(error);

                })}

        }else{

            return new Promise((resolve, reject) => {
                new TokenAuthService().logout();
                redirectToLogin(error);
                reject(error);
            });
        }
    }
  });

  const redirectToLogin = (error) =>{
    try {
      if (error.config.url.indexOf("auth/login") != -1){
        return;
      }
      document.location.href = "/";
    } catch (error) {
      document.location.href = "/";
    }
}

  export default axios;
