import axios from "../utilities/api-axios"

const apiUrl = process.env.REACT_APP_API_URL;

export class EventViewersService {
    getEventViewers() {
        return axios.get(`${apiUrl}/eventViewers/WithUser/`).then((res) => {
            return res.data;
        });
    }

    getEventViewersIdDescription() {
        return axios.get(`${apiUrl}/eventViewers/IdDescription/`).then((res) => res.data);
    }
}
