import axios from "../utilities/api-axios"

const apiUrl = process.env.REACT_APP_API_URL;

export class RolesService {
    getRoles() {
        return axios.get(`${apiUrl}/roles/`).then((res) => {
            return res.data;
        });
    }

    getRolesIdDescription() {
        return axios.get(`${apiUrl}/roles/IdDescription/`).then((res) => res.data);
    }
}
