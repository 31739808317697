import React, { useState, useEffect } from "react";

import classNames from "classnames";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
// import { Calendar } from "primereact/calendar";
// import { InputNumber } from "primereact/inputnumber";

import { $ } from "react-jquery-plugin";

import { Utils } from "../../utilities/Utils";
import { ErrorDialog, ErrorDialogEmpty, ErrorDialogSet, ErrorDialogSetResponse, ErrorDialogNoAuthorizedSet } from "../ErrorDialog";
import { UserService } from "../../service/UserService";
import { Password } from "../../components/login/Password";

export const EmailPersonalEditDialog = (props) => {
    let emptyUser = {
        useId: null,
        useName: "",
        useSurname: "",
        useEmail: "",
        usePhone: "",
        RolesId: [],
    };

    const [loading, setLoading] = useState(true);
    const [visible, setVisible] = useState(null);
    const [submitted, setSubmitted] = useState(false);

    const [user, setUser] = useState(emptyUser);
    const [password, setPassword] = useState("");

    const [stateErrorDialog, setStateErrorDialog] = useState(ErrorDialogEmpty);

    useEffect(() => {
        const load = async () => {
            setLoading(true);
            setSubmitted(false);

            setVisible(props.visibleDialog);

            let _user = { ...props.user };
            setUser(_user);
            setPassword("");

            setLoading(false);
        };
        load();
    }, [props.visibleDialog, props.user]);

    if (loading)
        return (
            <div className="loading-container">
                <h3>Loading...</h3>
            </div>
        );

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || "";
        let _user = { ...user };
        _user[`${name}`] = val;

        setUser(_user);
    };

    const validateRequired = () => {
        for (let k = 0; k < $("[Required]").length; k++) {
            if (!$("[Required]")[k].value) return false;
        }
        return true && password;
    };

    const closeCancel = () => {
        props.onClose(null);
    };
    const closeOk = (_item) => {
        setSubmitted(false);
        props.onClose(_item);
    };
    const cancel = () => {
        closeCancel();
    };

    const saveItem = async () => {
        setSubmitted(true);

        try {
            if (validateRequired()) {
                let _item = { ...user };

                if (_item.useId) {
                    let userService = new UserService();
                    userService
                        .updateEmailValidatedByPassword(_item.useEmail, password)
                        .then(() => {
                            closeOk(_item);
                        })
                        .catch((res) => {
                            setStateErrorDialog(ErrorDialogSetResponse(res));
                        });
                }
                // else {
                //     let userService = new userService();
                //     userService
                //         .createLicense(_item)
                //         .then((data) => {
                //             _license["LicId"] = data.licId;
                //             setLicense(_license);
                //             closeOk(_license);
                //         })
                //         .catch((res) => {
                //             setStateErrorDialog(ErrorDialogSetResponse(res));
                //         });
                // }
            }
        } catch (error) {
            setStateErrorDialog(ErrorDialogSet(true, ""));
        }
    };

    const eventDialogFooter = (
        <>
            <Button label="Cancel" className="btn-secondary " onClick={cancel} />
            <Button label="Save changes" className="btn-primary " onClick={saveItem} />
        </>
    );

    const isValid = () => {
        if (user.useEmail.length < 8 || user.useEmail.length > 50 || password.length < 8 || password.length > 20) {
            return false;
        }
        return true;
    };

    return (
        <>
            <Dialog visible={visible} style={{ width: "650px" }} header="Edit personal details" modal className="p-fluid" footer={eventDialogFooter} contentClassName="overflow-y-visible" onHide={closeCancel}>
                <div className="p-field">
                    <label htmlFor="mail">New email</label>
                    <InputText id="mail" maxLength="100" value={user.useEmail} onChange={(e) => onInputChange(e, "useEmail")} required="true" autoFocus className={classNames({ "p-invalid": submitted && !user.useEmail })} />
                    {submitted && !user.useEmail && <small className="p-invalid">Email is required.</small>}
                </div>

                <div className="p-field p-mb-2">
                    <label htmlFor="confirm" className="p-d-block">
                        Current password
                    </label>
                    {/* <InputText type="password" id="confirm" maxLength="20" value={password} placeholder="Password" required="true"
                    value={password} onChange={(e) => setPassword(e.target.value)}
                    /> */}
                    <Password value={password} onChange={setPassword} placeholder="Password" required={submitted} messageRequired="Password is required."></Password>
                </div>
                {/* {submitted && !password && <small className="p-invalid">Password is required. </small>} */}
                {/* {submitted && !isValid() && user.useEmail && <small className="p-invalid">Your email and password must be at least 8 characters.</small>} */}
            </Dialog>

            <ErrorDialog stateDialog={stateErrorDialog}></ErrorDialog>
        </>
    );
};
