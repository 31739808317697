import axios from "axios";
const apiUrl = process.env.REACT_APP_API_URL;

export class EventPlannersService {
    getEventPlanners() {
        return axios.get(`${apiUrl}/eventPlanners/WithUser/`).then((res) => res.data);
    }

    getEventPlannersIdDescription() {
        return axios.get(`${apiUrl}/eventPlanners/IdDescription/`).then((res) => res.data);
        // .catch(err => {throw new Error(err.response.status)});
        // const res = await axios.get(`${apiUrl}/eventPlanners/IdDescription/`).catch(err => {throw new Error(err.response.status)});
        // return res.data;
    }
}
