import { Button } from "primereact/button";
export const HeaderPageDetail = (props) => {
    return (
        <div className={props.headerStyles}>
            <div className="p-grid">
                {props.leftColumn}
                {props.rightColumn}
                {props.fullColumn}
            </div>
        </div>
    );
};
