import { Marker } from "@react-google-maps/api";
import moment from 'moment'

const ARROW_MARKER = "down-arrow.png";

export class ReferencePoint {
    setReferencePoint;
    referencePoint;

    constructor(referencePoint, setReferencePoint){
        this.setReferencePoint = setReferencePoint;
        this.referencePoint = referencePoint;
    }


    drawPointOnMap(lat, lng){
        let position = new window.google.maps.LatLng(parseFloat(lat), parseFloat(lng));
        this.setReferencePoint({...this.referencePoint, tags: [
                <Marker
                    icon={{ url: ARROW_MARKER }}
                    position={position}
                    clickable={false}
                    draggable={false}
                    editable={false}
                    draggable={false}
                    key={"referencePoint"}
                >
                </Marker>            
        ]});
    }


}