import React, { useState, useEffect, useRef } from "react";
import classNames from "classnames";
import { DataTable } from "primereact/datatable";
import { Utils } from "../utilities/Utils";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Calendar } from "primereact/calendar";
import { ClubService } from "../service/ClubService";
import { EventViewersService } from "../service/EventViewersService";
import { EventPlannersService } from "../service/EventPlannersService";
import { StateService } from "../service/StateService";
import { Dropdown } from "primereact/dropdown";
import { useHistory } from "react-router-dom";

export const EventsCurrents = (props) => {
    let emptyEvent = {
        EventId: null,
        Name: "",
        Start: null,
        Finish: null,
        ClubId: null,
        ClubName: "",
        Localization: "",
        Country: "",
        Invited: 0,
        Authorized: 0,
        Schedules: "",
    };

    var language = navigator.language || navigator.userLanguage;
    let dateFormat = "mm/dd/yy";

    if (language.toLowerCase().indexOf("es") != -1) {
        dateFormat = "dd/mm/yy";
    };

    const [errorDialog, setErrorDialog] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);
    const [events, setEvents] = useState(null);
    const [eventDialog, setEventDialog] = useState(false);
    const [deleteEventDialog, setDeleteEventDialog] = useState(false);
    const [deleteEventsDialog, setDeleteEventsDialog] = useState(false);
    const [event, setEvent] = useState(emptyEvent);
    const [selectedProducts, setSelectedEvents] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const [clubs, setClubs] = useState(null);
    const [eventViewers, setEventViewers] = useState(null);
    const [eventPlanners, setEventPlanners] = useState(null);
    const [states, setStates] = useState(null);
    const toast = useRef(null);
    const dt = useRef(null);
    const history = useHistory();

    // Se ejecuta luego del render
    useEffect(() => {
        const loadAsync = async () => {
            var res = null;
            try {
                const clubService = new ClubService();
                const resClubs = await clubService.getClubsIdDescription();
                setClubs(resClubs);
                res = resClubs;

                const eventViewerService = new EventViewersService();
                const resViewers = await eventViewerService.getEventViewersIdDescription();
                setEventViewers(resViewers);
                res = resViewers;

                const eventPlannersService = new EventPlannersService();
                const resPlanners = await eventPlannersService.getEventPlannersIdDescription();
                setEventPlanners(resPlanners);
                res = resPlanners;

                const stateService = new StateService();
                const resState = await stateService.getStatesIdDescription(600);
                setStates(resState);
                res = resState;

                setEvents(props.events);

                // if(params.message)
                //     toast.current.show({ severity: "success", summary: "Successful", detail: params.message, life: 3000 });

                // const eventService = new EventService();
                // const resEvents = await eventService.getEvents();
                // setEvents(resEvents);
                // res = resEvents;
            } catch (error) {
                setErrorMessage("");
                setErrorDialog(true);
                console.log(error.message);
            }
        };
        loadAsync();
    }, [props.events]);

    // const formatCurrency = (value) => {
    //     return value.toLocaleString("en-US", { style: "currency", currency: "USD" });
    // };

    // const openNew = () => {
    //     setEvent(emptyEvent);
    //     setSubmitted(false);
    //     setEventDialog(true);
    // };

    const hideDialog = () => {
        setSubmitted(false);
        setEventDialog(false);
    };

    const hideDeleteEventDialog = () => {
        setDeleteEventDialog(false);
    };

    const hideDeleteEventsDialog = () => {
        setDeleteEventsDialog(false);
    };

    const hideErrorDialog = () => {
        setErrorDialog(false);
    };

    // const closeDialog = (events) => {
    //     setEvents(events);
    //     setEventDialog(false);
    //     setEvent(emptyEvent);
    // };

    const editEvent = (event) => {
        history.push("/event/" + event.EventId);
        // setEvent({ ...event });
        // setEventDialog(true);
    };

    // const confirmDeleteEvent = (event) => {
    //     setEvent(event);
    //     setDeleteEventDialog(true);
    // };

    const deleteSelectedProducts = () => {
        let _events = events.filter((val) => !selectedProducts.includes(val));
        setEvents(_events);
        setDeleteEventsDialog(false);
        setSelectedEvents(null);
        toast.current.show({ severity: "success", summary: "Successful", detail: "Products Deleted", life: 3000 });
    };

    const onInputChange = (e, name) => {
        const val = (e.target && e.target.value) || "";
        let _event = { ...event };
        _event[`${name}`] = val;

        setEvent(_event);
    };

    // const onInputNumberChange = (e, name) => {
    //     const val = e.value || 0;
    //     let _event = { ...event };
    //     _event[`${name}`] = val;

    //     setEvent(_event);
    // };

    // #region Templatedatatable
    // const onErrorFind = (e, name) => {
    //     if (e) return e[0][`${name}`];
    //     else return "[error]";
    // };

    const nameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">{rowData.Name}</span>
            </>
        );
    };
    const clubBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">{rowData.ClubName}</span>
            </>
        );
    };

    const startFinishBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">{Utils.dateDDMMYY(rowData.Start) + " - " + Utils.dateDDMMYY(rowData.Finish)}</span>
            </>
        );
    };

    const localizationBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">{rowData.Localization}</span>
            </>
        );
    };

    const countryBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">{rowData.Country}</span>
            </>
        );
    };

    const invitedBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">{rowData.Invited}</span>
            </>
        );
    };

    // const authorizedBodyTemplate = (rowData) => {
    //     return (
    //         <>
    //             <span className="p-column-title">{rowData.Authorized}</span>
    //         </>
    //     );
    // };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions">
                <Button icon="pi pi-angle-right" onClick={() => editEvent(rowData)} />
                {/* <Button icon="pi pi-trash" className="btn-alert" onClick={() => confirmDeleteEvent(rowData)} /> */}
            </div>
        );
    };
    // #endregion

    const header = (
        <div className="table-header p-d-flex p-jc-between p-ai-center">
            <h2 className="p-m-0 fs-x-normal">Current Events</h2>
            <span className="p-input-icon-right">
                <i className="pi pi-search" />
                <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." className="p-inputtext p-component" />
            </span>
        </div>
    );

    const eventDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" /*onClick={saveEvent}*/ />
        </>
    );
    const deleteEventDialogFooter = (
        <>
            <Button label="Cancel" className="btn-secondary " onClick={hideDeleteEventDialog} />
            <Button label="Delete" className="btn-primary " /*onClick={deleteEvent}*/ />
        </>
    );
    const errorDialogFooter = (
        <>
            <Button label="Ok" icon="pi pi-times" className="p-button-text" onClick={hideErrorDialog} />
        </>
    );
    const deleteEventsDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteEventsDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteSelectedProducts} />
        </>
    );

    const formatDate = (d) => {
        if (d) d = new Date(d);
        return d;
    };

    // const validateRequired = () => {
    //     for (let k = 0; k < $("[Required]").length; k++) {
    //         if (!$("[Required]")[k].value) return false;
    //     }
    //     return true;
    // };

    return (
        <div className="card">
            <Toast ref={toast} />
            {/* <Toolbar className="p-mb-4" left={leftToolbarTemplate} right={rightToolbarTemplate}></Toolbar> */}

            <DataTable
                ref={dt}
                value={events}
                selection={selectedProducts}
                onSelectionChange={(e) => setSelectedEvents(e.value)}
                dataKey="EventId"
                paginator
                rows={10}
                rowsPerPageOptions={[5, 10, 25]}
                className="datatable-responsive table-layout-auto"
                paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} events"
                globalFilter={globalFilter}
                emptyMessage="No events found."
                header={header}
            >
                <Column selectionMode="multiple" className="is-first" />
                <Column field="Name" header="Name" sortable body={nameBodyTemplate} />
                <Column field="ClubName" header="Institutions" body={clubBodyTemplate} sortable />
                <Column field="Start" header="Start and Finish Date" body={startFinishBodyTemplate} headerClassName="txt-center" bodyClassName="txt-center" sortable />
                <Column field="Localization" header="Localization" body={localizationBodyTemplate} headerClassName="txt-center" bodyClassName="txt-center" sortable />
                <Column field="Country" header="Country" body={countryBodyTemplate} headerClassName="txt-center" bodyClassName="txt-center" sortable />
                <Column field="Invited" header="Invited" body={invitedBodyTemplate} headerClassName="txt-center" bodyClassName="txt-right" sortable />
                {/* <Column field="Authorized" header="Authorized" body={authorizedBodyTemplate} headerClassName="txt-center" bodyClassName="txt-right" sortable /> */}

                <Column body={actionBodyTemplate} className="is-last" />
            </DataTable>

            <Dialog visible={eventDialog} style={{ width: "450px" }} header="Event details" modal className="p-fluid" footer={eventDialogFooter} onHide={hideDialog}>
                <div className="p-field">
                    <label className="p-mb-3">Planner</label>
                    <Dropdown id="planner" optionLabel="Description" optionValue="Id" value={event.eplId} options={eventPlanners} onChange={(e) => onInputChange(e, "eplId")} required autoFocus className={classNames({ "p-invalid": submitted && !event.eplId })} />
                    {submitted && !event.eplId && <small className="p-invalid">Event planner is required.</small>}
                </div>
                <div className="p-field">
                    <label className="p-mb-3">Viewer</label>
                    <Dropdown id="viewer" optionLabel="Description" optionValue="Id" value={event.vieId} options={eventViewers} onChange={(e) => onInputChange(e, "vieId")} required autoFocus className={classNames({ "p-invalid": submitted && !event.vieId })} />
                    {submitted && !event.vieId && <small className="p-invalid">Event viewer is required.</small>}
                </div>
                <div className="p-field">
                    <label className="p-mb-3">State</label>
                    <Dropdown id="state" optionLabel="Description" optionValue="Id" value={event.stsId} options={states} onChange={(e) => onInputChange(e, "stsId")} required autoFocus className={classNames({ "p-invalid": submitted && !event.stsId })} />
                    {submitted && !event.stsId && <small className="p-invalid">State is required.</small>}
                </div>
                <div className="p-field">
                    <label htmlFor="name">Name</label>
                    <InputText id="name" maxLength="100" value={event.eveName} onChange={(e) => onInputChange(e, "eveName")} required className={classNames({ "p-invalid": submitted && !event.eveName })} />
                    {submitted && !event.eveName && <small className="p-invalid">Name is required.</small>}
                </div>
                <div className="p-field">
                    <label htmlFor="start">Start</label>
                    <Calendar id="start" value={formatDate(event.eveStart)} dateFormat={dateFormat} onChange={(e) => onInputChange(e, "eveStart")} required showIcon={true} className={classNames({ "p-invalid": submitted && !event.eveStart })} />
                    {submitted && !event.eveStart && <small className="p-invalid">Start is required.</small>}
                </div>
                <div className="p-field">
                    <label htmlFor="finish">Finish</label>
                    <Calendar id="finish" value={formatDate(event.eveFinish)} dateFormat={dateFormat} onChange={(e) => onInputChange(e, "eveFinish")} required showIcon={true} className={classNames({ "p-invalid": submitted && !event.eveFinish })} />
                    {submitted && !event.eveFinish && <small className="p-invalid">Finish is required.</small>}
                </div>

                <div className="p-field">
                    <label className="p-mb-3">Club</label>
                    <Dropdown id="club" optionLabel="Description" optionValue="Id" value={event.cluId} options={clubs} onChange={(e) => onInputChange(e, "cluId")} required className={classNames({ "p-invalid": submitted && !event.cluId })} />
                    {submitted && !event.cluId && <small className="p-invalid">Club is required.</small>}
                </div>
            </Dialog>

            <Dialog visible={deleteEventDialog} className="p-dialog-small" header="Delete event" modal footer={deleteEventDialogFooter} onHide={hideDeleteEventDialog}>
                <div className="confirmation-content">
                    {event && (
                        <span>
                            Are you sure you want to delete <b>{event.name}</b>? <br />
                            They will no longer be able to access the platform
                        </span>
                    )}
                </div>
            </Dialog>

            <Dialog visible={deleteEventsDialog} style={{ width: "450px" }} header="Confirm" modal footer={deleteEventsDialogFooter} onHide={hideDeleteEventsDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: "2rem" }} />
                    {event && <span>Are you sure you want to delete the selected events?</span>}
                </div>
            </Dialog>

            <Dialog visible={errorDialog} style={{ width: "450px" }} header="Atention" modal footer={errorDialogFooter} onHide={hideErrorDialog}>
                <div className="confirmation-content">
                    <i className="pi pi-exclamation-triangle p-mr-3" style={{ fontSize: "2rem" }} />
                    <span>{errorMessage === "" ? "An error has occurred. Please check error log." : errorMessage}</span>
                </div>
            </Dialog>
        </div>
    );
};
